import { ILocalStorageClient } from "../../infrastructure/local-storage-client";
import { getKey } from "./helper";
import { MasterData } from "../../entity";
import { QuestionnaireData } from "../../entity/questionnaire-data";

export interface IPersistentDataModule {
  getListenedGuideIds: () => string[];
  getCurrentLanguageId: () => string | null;
  getQuestionnaireData: () => QuestionnaireData | null;
  updateListenedGuideIds: (ids: string[]) => void;
  updateCurrentLanguageId: (id: string) => void;
}

export class PersistentDataModule implements IPersistentDataModule {
  private readonly localStorageClient: ILocalStorageClient;
  private readonly applicationId: string;

  public constructor(
    localStorageClient: ILocalStorageClient,
    masterData: MasterData
  ) {
    this.localStorageClient = localStorageClient;
    this.applicationId = masterData.application.id;
    this.restoreListenedGuideIds(masterData.guides.map((x) => x.id));
  }

  public getListenedGuideIds = () => {
    const listenedGuideIdsString: string =
      this.localStorageClient.get(
        getKey(this.applicationId, "listenedGuideIds")
      ) || "";
    if (listenedGuideIdsString.length == 0) return [];
    return listenedGuideIdsString.split(",");
  };

  public getCurrentLanguageId = () =>
    this.localStorageClient.get(
      getKey(this.applicationId, "currentLanguageId")
    );

  updateListenedGuideIds = (ids: string[]) =>
    this.localStorageClient.set(
      getKey(this.applicationId, "listenedGuideIds"),
      ids
    );
  updateCurrentLanguageId = (id: string) =>
    this.localStorageClient.set(
      getKey(this.applicationId, "currentLanguageId"),
      id
    );

  getQuestionnaireData = (): QuestionnaireData | null => {
    return this.localStorageClient.get("questionnaireData") || null;
  };

  // ガイドの更新が行われ、削除された guideId がローカルストレージに保存されていた場合、削除し、保存し直す
  private restoreListenedGuideIds = (guideIds: string[]) => {
    const listenedGuideIds: string[] = this.getListenedGuideIds();
    if (listenedGuideIds.length == 0) return;

    const filteredGuideIds = listenedGuideIds.filter((x) =>
      guideIds.includes(x)
    );
    this.updateListenedGuideIds(filteredGuideIds);
  };
}
