import { twMerge } from "tailwind-merge";

type Props = {
  active: boolean;
  title: string;
  onClick: () => void;
  height?: number;
  textSize?: number;
};

export const QuestionnaireButton = ({
  active,
  title,
  onClick,
  height,
  textSize,
}: Props) => (
  <button
    onClick={onClick}
    className={twMerge(
      "h-[44px] border-2 rounded-[10px] px-[12px] text-[18px] line-[18px] border-[#bb5730] transition-all duration-100 active:scale-95",
      active ? "text-white" : "text-[#bb5730]",
      active ? "bg-[#bb5730]" : "bg-white",
      textSize ? `text-[${textSize}px]` : "text-[18px]",
      height ? `h-[${height}px]` : "h-[44px]"
    )}
  >
    <div className={"flex flex-col justify-center items-center"}>
      <div className={"h-[30px] content-center"}>{title}</div>
    </div>
  </button>
);
