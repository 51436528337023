import styled from "styled-components";
import { motion, PanInfo } from "framer-motion";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "@agp/shared.common/extensions/number.extensions";
import { IAudioPlayerTrack } from "@agp/shared.user-app/module/guide-player.module/audio-client";
import ServiceLocator from "../../../../../domain/service-locator";

const barHeight = 4;
const circleSize = 18;

const clamp = (num: number, min: number, max: number) =>
  Math.min(Math.max(num, min), max);

const convertToDisplayTime = (time: number) =>
  `${Math.floor(time / 60)}:${Math.floor(time % 60).toPadString(2)}`;

export const MinpakuSeekBar = (props: { withTime: boolean }) => {
  const guidePlayerModule = ServiceLocator.GuidePlayerModule;
  const sliderRef = useRef<HTMLDivElement>(null);
  const circleRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [currentSeekRate, setCurrentSeekRate] = useState(0);
  const [circlePosition, setCirclePosition] = useState(0);

  const currentTrack = guidePlayerModule.currentTrack.watch();
  const currentSeek = guidePlayerModule.currentSeek.watch();
  const isSeeking = guidePlayerModule.isSeeking.watch();

  const duration = useMemo(
    () => (currentTrack as IAudioPlayerTrack)?.duration || 0,
    [currentTrack]
  );

  const displayDuration = useMemo(
    () => convertToDisplayTime(duration),
    [duration]
  );

  const displayCurrentTime = useMemo(
    () => convertToDisplayTime(currentSeek),
    [currentSeek]
  );

  const getSeekRate = useCallback(
    (circleX: number) => {
      const sliderRect = sliderRef.current?.getBoundingClientRect();
      if (!sliderRect) return 0;
      return clamp(
        (circleX + circleSize / 2 - sliderRect.left) / sliderRect.width,
        0,
        1
      );
    },
    [circleRef.current]
  );

  const onDragStart = useCallback(
    (e: MouseEvent | TouchEvent | PointerEvent, _: PanInfo) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(true);
    },
    []
  );

  const onDrag = useCallback(
    (e: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
      e.preventDefault();
      e.stopPropagation();
      const rate = getSeekRate(info.point.x);
      setCurrentSeekRate(rate);
      setIsDragging(true);
    },
    [getSeekRate]
  );

  const onDragEnd = useCallback(
    (_: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
      const seekRate = getSeekRate(info.point.x);
      guidePlayerModule.seek(duration * seekRate);
      setCurrentSeekRate(seekRate);
      setCirclePosition(seekRate);
      setTimeout(() => {
        setIsDragging(false);
      }, 100);
    },
    [getSeekRate, guidePlayerModule, duration]
  );

  useEffect(() => {
    if (!isDragging && duration !== 0 && !isSeeking) {
      const seekRate = currentSeek / duration;

      if (seekRate === 0) {
        setCurrentSeekRate(0);
        return;
      }

      setCurrentSeekRate(seekRate);
      setCurrentSeekRate((x) => {
        const diffMilliseconds = Math.abs(x * duration - currentSeek) * 1000;
        return diffMilliseconds < 500 ? seekRate : x;
      });
    }
  }, [duration, currentSeek, isDragging, isSeeking]);

  useEffect(() => {
    if (!isDragging && !isSeeking) {
      setCirclePosition(currentSeekRate);
    }
  }, [currentSeekRate, isDragging, isSeeking]);

  useEffect(() => {
    setCurrentSeekRate(0);
  }, [currentTrack]);

  return (
    <Wrapper>
      <Slider ref={sliderRef}>
        <WhiteBar />
        <BlackBar position={currentSeekRate} />
        <SeekPositionCircleWrapper
          ref={circleRef}
          style={!isDragging ? { transform: "none" } : {}}
          position={circlePosition}
          drag="x"
          isDragging={isDragging}
          whileTap={{ scale: 2 }}
          dragElastic={false}
          dragSnapToOrigin
          onDragStart={onDragStart}
          onDrag={onDrag}
          onDragEnd={onDragEnd}
          dragConstraints={sliderRef}
        >
          <div className="bg-black rounded-full w-full h-full" />
        </SeekPositionCircleWrapper>
      </Slider>
      {props.withTime && (
        <TimeWrapper className="text-2sm -mt-1">
          <div>{displayCurrentTime}</div>
          <div>{displayDuration}</div>
        </TimeWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(motion.div)`
  position: relative;
`;

const Slider = styled(motion.div)`
  position: relative;
  margin-left: ${circleSize / 2}px;
  margin-right: ${circleSize / 2}px;
  height: 10px;
`;

const BarBase = styled.div`
  position: absolute;
  margin-top: ${-barHeight / 2}px;
  width: 100%;
  height: ${barHeight}px;
  border-radius: 3px;
  background: #867982;
`;

const WhiteBar = styled(BarBase)`
  background: darkgrey;
`;

const BlackBar = styled(BarBase)<{ position: number }>`
  background: #867982;
  width: ${(props) => props.position * 100}%;
`;

const SeekPositionCircleWrapper = styled(motion.div)<{
  position: number;
  isDragging: boolean;
}>`
  position: absolute;
  margin-top: ${-circleSize / 2}px;
  margin-left: ${-circleSize / 2}px;
  height: ${circleSize}px;
  width: ${circleSize}px;
  left: ${(props) => props.position * 100}%;
  padding: 3px;
`;

const TimeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: ${circleSize / 2}px;
  margin-right: ${circleSize / 2}px;
`;
