import { FadeModal } from "../../../../components/modal/fade-modal";
import React from "react";

type QuestionnaireCompleteSubmitModalProps = {
  open: boolean;
  onClose: () => void;
};

export const QuestionnaireCompleteSubmitModal = (
  props: QuestionnaireCompleteSubmitModalProps
) => {
  return (
    <FadeModal isOpen={props.open} onClose={props.onClose}>
      <div className={"flex flex-col text-center gap-6 items-center px-5 py-5"}>
        <div className={"font-bold text-[20px]"}>
          アンケートご協力
          <br />
          ありがとうございました。
        </div>
        <div className={"text-[16px]"}>
          引き続き、みんぱく音声ガイド
          <br />
          をお楽しみください。
        </div>
        <div
          className={
            "w-24 h-16 rounded-[10px] border-[#bb5730] border-2 flex justify-center items-center text-[16px] font-bold text-[#bb5730]"
          }
          onClick={props.onClose}
        >
          確認
        </div>
      </div>
    </FadeModal>
  );
};
