import { IActivityLogClient } from "../../infrastructure/activity-log-client";
import { MasterData } from "../../entity";
import { LanguageRepository } from "../language.repository";

export class ActivityLogModule {
  private readonly _activityLogClient: IActivityLogClient;
  private readonly _masterData: MasterData;
  private readonly _defaultLanguageId: string;
  private readonly _languageRepository: LanguageRepository;

  public constructor(
    activityLogClient: IActivityLogClient,
    masterData: MasterData,
    languageRepository: LanguageRepository
  ) {
    this._activityLogClient = activityLogClient;
    this._masterData = masterData;
    this._languageRepository = languageRepository;
    this._defaultLanguageId =
      masterData.languages.find((x) => x.isDefault)?.id ??
      masterData.languages[0].id;
  }

  public setUserProperty(property: object) {
    this._activityLogClient.trySetUserPropertiesAsync(null, property);
  }

  public sendPageViewEvent(pageName: string, pagePath: string) {
    this._activityLogClient.trySendEventAsync("page_view", {
      page_title: pageName,
      page_path: pagePath,
    });
  }

  public sendQuestionnaireEvent(data: object) {
    this._activityLogClient.trySendEventAsync("answer_questionnaire", data);
  }

  public sendSelectGuideEvent(id: string) {
    const guide = this._masterData.guides.find((guid) => guid.id === id);
    const defaultLanguageGuideName = guide?.name[this._defaultLanguageId];
    const tour = this._masterData.tours.find((x) => x.id === guide?.tourId);
    const defaultLanguageTourName = tour?.name[this._defaultLanguageId];
    const currentLanguage = this._masterData.languages.find(
      (x) => x.id == this._languageRepository.currentLanguageId.value
    );
    this._activityLogClient.trySendEventAsync("select_guide", {
      guide_name: defaultLanguageGuideName,
      tour_name: defaultLanguageTourName,
      language_name: currentLanguage?.name,
    });
  }
}
