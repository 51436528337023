import Analytics, { AnalyticsInstance } from "analytics";
import { IActivityLogClient } from "@agp/shared.user-app/infrastructure/activity-log-client";
import googleAnalytics, { GtagOptions } from "@analytics/google-analytics";

export class GaClient implements IActivityLogClient {
  private analyticsInstance: AnalyticsInstance;

  public constructor(
    applicationName: string,
    trackingId: string,
    gtagOption?: GtagOptions
  ) {
    this.analyticsInstance = Analytics({
      app: applicationName,
      plugins: [
        googleAnalytics({
          measurementIds: [trackingId],
          gtagConfig: gtagOption,
        }),
      ],
    });
  }

  public async trySendEventAsync(name: string, param: object): Promise<void> {
    try {
      await this.analyticsInstance.track(name, param);
    } catch (e) {
      console.error(e);
    }
  }

  public async trySetUserPropertiesAsync(
    userId: string | null,
    param: object
  ): Promise<void> {
    try {
      await this.analyticsInstance.identify(userId as unknown as string, param);
    } catch (e) {
      console.error(e);
    }
  }
}
