import styled from "styled-components";
import { motion } from "framer-motion";
import {
  ReactZoomPanPinchRef,
  TransformComponent,
  TransformWrapper,
} from "react-zoom-pan-pinch";
import { Image } from "semantic-ui-react";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { Guide } from "@agp/shared.user-app/entity";
import ServiceLocator from "../../../domain/service-locator";
import { headerHeight } from "../../components/layout";

interface LocalMapProps {
  guides: Guide[];
  mapImageUrl: string;
}

export const LocalMap = (props: LocalMapProps) => {
  /**
   * Prepare Modules
   */
  const playerLayoutModule = ServiceLocator.PlayerLayoutModule;
  const guidePlayerModule = ServiceLocator.GuidePlayerModule;

  /**
   * useRef
   */
  const transformComponent = useRef<ReactZoomPanPinchRef>(null!);

  /**
   * watch ReactiveProperty
   */
  const playerType = playerLayoutModule.playerType.watch();
  const playerHeight = playerLayoutModule.playerHeight.watch();
  const currentTrack = guidePlayerModule.currentTrack.watch();

  /**
   * useState
   */
  const [scale, setScale] = useState<number>(1);

  /**
   * useMemo
   */
  const pinScale = useMemo(() => Math.min(Math.max(1 / scale, 0), 1), [scale]);
  const selectedGuideIndex = useMemo(() => {
    if (!currentTrack) return -1;
    return props.guides.findIndex((x) => x.id === currentTrack.id);
  }, [props.guides, currentTrack]);
  const bottomPlayerMargin = useMemo(
    () => (playerType === "full" ? 0 : playerHeight),
    [playerType, playerHeight]
  );

  /**
   * useCallback
   */
  const onZoomEnd = useCallback((scale: number) => setScale(scale), []);

  const onClickPin = useCallback(
    (index: number) => {
      const wrapperHeight =
        window.innerHeight -
        playerLayoutModule.playerRectInfo.value.middlePlayerHeight -
        headerHeight;
      const transformHeight =
        transformComponent.current?.instance.contentComponent?.offsetHeight ||
        0;
      const transformWidth =
        transformComponent.current?.instance.contentComponent?.offsetWidth || 0;
      const guide = props.guides[index];
      const newPositionX =
        window.innerWidth / 2 - transformWidth * scale * guide.pinPosition.x;
      const newPositionY =
        wrapperHeight / 2 - transformHeight * scale * guide.pinPosition.y;
      transformComponent.current?.setTransform(
        newPositionX,
        newPositionY,
        scale
      );
      guidePlayerModule.select(props.guides[index].id);
      playerLayoutModule.playerType.setValue("middle");
      guidePlayerModule.play();
    },
    [
      guidePlayerModule,
      playerLayoutModule.playerRectInfo.value.middlePlayerHeight,
      playerLayoutModule.playerType,
      props.guides,
      scale,
    ]
  );

  return (
    <Wrapper
      animate={{ paddingBottom: bottomPlayerMargin }}
      transition={{
        type: "tween",
        duration: 0.3,
      }}
    >
      <div style={{ margin: "0 auto", width: "fit-content" }}>
        <TransformWrapper
          onWheelStop={(ref) => onZoomEnd(ref.state.scale)}
          onPinchingStop={(ref) => onZoomEnd(ref.state.scale)}
          ref={transformComponent}
        >
          <TransformComponent
            wrapperStyle={{
              overflow: "visible",
              height: "100%",
            }}
          >
            <Image
              src={props.mapImageUrl}
              style={{
                height: `calc(${window.innerHeight}px - 150px)`,
              }}
            />
            {props.guides.map((x, i) => (
              <CircleWrapper
                id={`circle-${x.trackNumber}`}
                key={`circle-${x.trackNumber}`}
                onClick={() => {
                  onClickPin(i);
                }}
                initial={{
                  x: "-50%",
                  y: "50%",
                }}
                animate={{
                  scale: pinScale * (i === selectedGuideIndex ? 1 : 0.5),
                  x: "-50%",
                  y: "50%",
                }}
                x={x.pinPosition.x}
                y={1 - x.pinPosition.y}
                isActive={i === selectedGuideIndex}
                transition={{
                  type: "tween",
                  duration: 0.3,
                }}
              >
                <Circle isActive={i === selectedGuideIndex}>
                  <TrackNumber>{x.trackNumber}</TrackNumber>
                </Circle>
              </CircleWrapper>
            ))}
          </TransformComponent>
        </TransformWrapper>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled(motion.div)`
  height: 100%;
  position: relative;
`;

const CircleWrapper = styled(motion.div)<{
  x: number;
  y: number;
  isActive: boolean;
}>`
  position: absolute;
  left: ${(props) => props.x * 100}%;
  bottom: ${(props) => props.y * 100}%;
  z-index: ${(props) => (props.isActive ? 1000 : 0)};
`;

const Circle = styled.div<{ isActive: boolean }>`
  width: 60px;
  height: 60px;
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) =>
    props.isActive
      ? props.theme.color.primaryBackground
      : props.theme.color.primary};
  color: ${(props) =>
    props.isActive
      ? props.theme.color.primary
      : props.theme.color.primaryBackground};
  ${(props) =>
    props.isActive && `border: 3px solid ${props.theme.color.primary}`}
`;

const TrackNumber = styled.div`
  pointer-events: none;
  font-size: 2.5rem;
`;
