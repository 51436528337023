import styled from "styled-components";
import { motion } from "framer-motion";
import { ReactNode, useCallback, useState } from "react";
import PlayerMiniComponent from "./player-mini-component";
import PlayerMiddleComponent from "./player-middle-component";
import ServiceLocator from "../../../../domain/service-locator";
import MinpakuPlayerMiniComponent from "./minapku/mini/minpaku-player-mini-component";
import MinpakuPlayerMiddleComponent from "./minapku/middle/minpaku-player-middle-component";
import { zIndexDefine } from "../../../constants/z-index";

const AudioPlayerComponent = () => {
  const playerLayoutModule = ServiceLocator.PlayerLayoutModule;
  const playerTranslateY = playerLayoutModule.playerTranslateY.watch();
  const playerType = playerLayoutModule.playerType.watch();
  const uiTemplate = ServiceLocator.UiTemplateModule.uiTemplate;

  // useCallback
  // const onPan = useCallback((_: never, info: PanInfo) => {
  // if (playerLayoutModule.playerType.value !== "full" && info.delta.y < 0) {
  //   playerLayoutModule.playerType.setValue("full");
  //   return;
  // }
  // if (playerLayoutModule.playerType.value !== "mini" && info.delta.y > 0) {
  //   playerLayoutModule.playerType.setValue("mini");
  //   return;
  // }
  // }, []);

  return (
    <PlayerBodyWrapper
      id="Player"
      layout
      initial={{ y: 0 }}
      animate={{
        y: playerTranslateY,
        borderRadius:
          uiTemplate === "Minpaku" ? 15 : playerType === "mini" ? 0 : 15,
      }}
      exit={{ y: 0 }}
      transition={{
        type: "tween",
        duration: 0.3,
      }}
      isMiniPlayer={playerType === "mini"}
      // onPan={onPan}
    >
      <PlayerComponentWrapper
        componentKey="mini-player"
        visible={playerType === "mini"}
      >
        {uiTemplate === "Minpaku" ? (
          <MinpakuPlayerMiniComponent />
        ) : (
          <PlayerMiniComponent />
        )}
      </PlayerComponentWrapper>
      <PlayerComponentWrapper
        componentKey="full-player"
        visible={playerType === "middle" || playerType === "full"}
      >
        {uiTemplate === "Minpaku" ? (
          <MinpakuPlayerMiddleComponent />
        ) : (
          <PlayerMiddleComponent />
        )}
      </PlayerComponentWrapper>
    </PlayerBodyWrapper>
  );
};
export default AudioPlayerComponent;

const PlayerBodyWrapper = styled(motion.div)<{ isMiniPlayer: boolean }>`
  position: fixed;
  left: 0;
  right: 0;
  top: ${window.innerHeight}px;
  height: ${window?.innerHeight * 2 + "px" ?? "200vh"};
  z-index: ${zIndexDefine.player};
  background: ${(props) => props.theme.color.secondaryBackground};
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.3));
  overflow: hidden;
`;

const PlayerComponentWrapper = (props: {
  componentKey: string;
  visible: boolean;
  children: ReactNode;
}) => {
  const [isDisplay, setIsDisplay] = useState<boolean>(props.visible);
  const onAnimationStart = useCallback(
    () => props.visible && setIsDisplay(true),
    [props.visible]
  );
  const onAnimationComplete = useCallback(
    () => !props.visible && setIsDisplay(false),
    [props.visible]
  );
  return (
    <motion.div
      key={props.componentKey}
      initial={{ opacity: 0 }}
      animate={{
        opacity: props.visible ? 1 : 0,
        transition: { duration: 0.3 },
      }}
      exit={{ opacity: 0, transition: { duration: 0.15 } }}
      onAnimationStart={onAnimationStart}
      onAnimationComplete={onAnimationComplete}
      style={{
        position: "absolute",
        display: isDisplay ? "block" : "none",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 501,
      }}
    >
      {props.children}
    </motion.div>
  );
};
