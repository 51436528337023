import { useCallback, useMemo, useState } from "react";
import { FadeModal } from "../../../../components/modal/fade-modal";
import { Divider } from "semantic-ui-react";
import { QuestionnaireButton } from "./questionaire-button";
import { twMerge } from "tailwind-merge";
import {
  AgeType,
  AudioGuideUsageCountType,
  DissatisfactionReason,
  JobType,
  AcoustiGuideQuestionnaireData,
  SatisfactionLevelType,
} from "./acousti-guide-questionnaire-data";

type QuestionnaireModalProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: AcoustiGuideQuestionnaireData) => void;
};

const ages: AgeType[] = [
  "10歳未満",
  "10代",
  "20代",
  "30代",
  "40代",
  "50代",
  "60代",
  "70代以上",
];
const jobs: JobType[] = ["学生", "社会人", "その他"];
const audioGuideUsageCountTypes: AudioGuideUsageCountType[] = [
  "初めて",
  "2～4回",
  "5～9回",
  "10回以上",
];
const satisfactionLevelTypes: SatisfactionLevelType[] = [
  "満足",
  "どちらともいえない",
  "満足していない",
];

export const QuestionnaireFormModal = (props: QuestionnaireModalProps) => {
  const [age, setAge] = useState<AgeType | null>(null);
  const [job, setJob] = useState<JobType | null>(null);
  const [audioGuideUsageCount, setAudioGuideUsageCount] =
    useState<AudioGuideUsageCountType | null>(null);
  const [satisfactionLevel, setSatisfactionLevel] =
    useState<SatisfactionLevelType | null>(null);
  const [dissatisfactionTemplateReasons, setDissatisfactionTemplateReasons] =
    useState<DissatisfactionReason[]>([]);
  const [dissatisfactionTextReason, setDissatisfactionTextReason] =
    useState<string>("");

  const disabledSubmitButton = useMemo(
    () =>
      age == null ||
      job == null ||
      audioGuideUsageCount == null ||
      satisfactionLevel == null ||
      (satisfactionLevel === "満足していない" &&
        dissatisfactionTemplateReasons.length === 0 &&
        dissatisfactionTextReason.length === 0),
    [
      age,
      audioGuideUsageCount,
      dissatisfactionTemplateReasons.length,
      dissatisfactionTextReason.length,
      job,
      satisfactionLevel,
    ]
  );

  const handleSubmit = useCallback(() => {
    props.onSubmit({
      age: age,
      job: job,
      audio_guide_usage_count: audioGuideUsageCount,
      satisfaction_level: satisfactionLevel,
      dissatisfaction_reason: `${dissatisfactionTemplateReasons.join(",")}${
        dissatisfactionTextReason.length > 0
          ? `,${dissatisfactionTextReason}`
          : ""
      }`,
    } as AcoustiGuideQuestionnaireData);
  }, [
    age,
    audioGuideUsageCount,
    dissatisfactionTemplateReasons,
    dissatisfactionTextReason,
    job,
    props,
    satisfactionLevel,
  ]);

  return (
    <FadeModal isOpen={props.open} onClose={props.onClose}>
      <div
        className={twMerge(
          "relative px-3 py-5 flex flex-col gap-12 overflow-y-auto hidden-scrollbar h-[80vh]"
        )}
      >
        <div
          className={"absolute top-0 right-2 w-10 h-10"}
          onClick={props.onClose}
        >
          <img src="/minpaku-demo/icons/close.svg" className="w-full" />
        </div>
        <div className={"w-full flex justify-center text-4xl font-bold"}>
          アンケート
        </div>
        <QuestionnaireArea
          questionAreaTitle={"年齢"}
          questionItemNames={ages}
          onSelect={(x) => setAge(x)}
          currentSelectItem={age}
        />
        <QuestionnaireArea
          questionAreaTitle={"職業"}
          questionItemNames={jobs}
          onSelect={(x) => setJob(x)}
          currentSelectItem={job}
        />
        <QuestionnaireArea
          questionAreaTitle={"音声ガイド利用回数"}
          questionItemNames={audioGuideUsageCountTypes}
          onSelect={(x) => setAudioGuideUsageCount(x)}
          currentSelectItem={audioGuideUsageCount}
        />
        <QuestionnaireArea
          questionAreaTitle={"満足度"}
          questionItemNames={satisfactionLevelTypes}
          onSelect={(x) => setSatisfactionLevel(x)}
          currentSelectItem={satisfactionLevel}
        />
        {satisfactionLevel === "満足していない" && (
          <div className={"flex flex-col"}>
            <div className={"text-2xl font-bold"}>
              「満足していない」と答えた方へ
              <Divider />
            </div>
            <div className={"text-xl mb-4"}>理由を教えてください</div>
            <div className={"flex flex-col gap-[20px] flex-wrap mb-4"}>
              <DissatisfactionReasonButton
                reasonString={"使い方が分かりにくい"}
                dissatisfactionReasons={dissatisfactionTemplateReasons}
                setReason={setDissatisfactionTemplateReasons}
              />
              <DissatisfactionReasonButton
                reasonString={"内容が難しい"}
                dissatisfactionReasons={dissatisfactionTemplateReasons}
                setReason={setDissatisfactionTemplateReasons}
              />
              <DissatisfactionReasonButton
                reasonString={"時間が足りなかった"}
                dissatisfactionReasons={dissatisfactionTemplateReasons}
                setReason={setDissatisfactionTemplateReasons}
              />
              <DissatisfactionReasonButton
                reasonString={"情報量が足りなかった"}
                dissatisfactionReasons={dissatisfactionTemplateReasons}
                setReason={setDissatisfactionTemplateReasons}
              />
            </div>
            <div>
              音声ガイドに関して改善してほしい点や追加してほしい内容があればご入力ください
              <div>
                <div className="mt-2">
                  <textarea
                    id="comment"
                    name="comment"
                    rows={4}
                    className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-[#bb5730] sm:text-sm/6"
                    defaultValue={""}
                    value={dissatisfactionTextReason}
                    onChange={(e) =>
                      setDissatisfactionTextReason(e.target.value)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <button
          onClick={handleSubmit}
          className={twMerge(
            "flex-shrink-0 rounded-[10px] border-[#bb5730] border-4 h-[44px] w-full text-[20px] font-bold text-[#bb5730] mt-8 mb-4",
            !disabledSubmitButton ? "border-[#bb5730]" : "border-grey",
            !disabledSubmitButton ? "text-[#bb5730]" : "text-gray-400"
          )}
        >
          回答
        </button>
      </div>
    </FadeModal>
  );
};

type QuestionnaireAreaProps<T> = {
  questionAreaTitle: string;
  questionItemNames: T[];
  onSelect: (p: T) => void;
  currentSelectItem: T | null;
};

const QuestionnaireArea = <T,>(props: QuestionnaireAreaProps<T>) => {
  return (
    <div>
      <div className={"text-3xl font-bold"}>{props.questionAreaTitle}</div>
      <Divider />
      <div className={"w-full flex gap-[12px] flex-wrap"}>
        {props.questionItemNames.map((x) => (
          <QuestionnaireButton
            key={`${x}`}
            active={x === props.currentSelectItem}
            title={`${x}`}
            onClick={() => props.onSelect(x)}
          />
        ))}
      </div>
    </div>
  );
};

type DissatisfactionReasonButtonProps = {
  reasonString: string;
  dissatisfactionReasons: DissatisfactionReason[];
  setReason: (dissatisfactionReasons: DissatisfactionReason[]) => void;
};

const DissatisfactionReasonButton = (
  props: DissatisfactionReasonButtonProps
) => {
  const active = useMemo(
    () => props.dissatisfactionReasons.includes(props.reasonString),
    [props.dissatisfactionReasons, props.reasonString]
  );
  return (
    <QuestionnaireButton
      active={active}
      title={props.reasonString}
      onClick={() => {
        if (active) {
          props.setReason([
            ...props.dissatisfactionReasons.filter(
              (x) => x !== props.reasonString
            ),
          ]);
          return;
        }
        props.setReason([...props.dissatisfactionReasons, props.reasonString]);
      }}
      height={48}
    />
  );
};
