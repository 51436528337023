import { ReactivePropertyInstance } from "@agp/shared.common/use-reactive-property";
import {
  IAudioPlayerClient,
  IAudioPlayerTrack,
} from "@agp/shared.user-app/module/guide-player.module/audio-client";

export class AudioPlayerClient implements IAudioPlayerClient {
  public static ElementId = "audio-player-client-element";
  public currentLibrary: IAudioPlayerTrack[] | null = null;
  public readonly currentTrack =
    new ReactivePropertyInstance<IAudioPlayerTrack | null>(null);
  public readonly currentSeek = new ReactivePropertyInstance<number>(0);
  public readonly isPlaying = new ReactivePropertyInstance<boolean>(false);
  public readonly isSeeking = new ReactivePropertyInstance<boolean>(false);

  private audioElement: HTMLAudioElement;

  public constructor() {
    this.removeAudioElement();

    this.audioElement = this.addAudioElement();

    this.audioElement.onplay = () => this.isPlaying.setValue(true);
    this.audioElement.onpause = () => this.isPlaying.setValue(true);
    this.audioElement.onabort = () => this.isPlaying.setValue(false);
    this.audioElement.onclose = () => this.isPlaying.setValue(false);
    this.audioElement.onchange = () => this.isPlaying.setValue(false);
    this.audioElement.ontimeupdate = () =>
      this.currentSeek.setValue(this.audioElement.currentTime);
  }

  private addAudioElement = (): HTMLAudioElement => {
    const rootElement = document.getElementById("root") as HTMLElement;
    const audioElement = document.createElement("audio");
    audioElement.id = AudioPlayerClient.ElementId;
    rootElement.appendChild(audioElement);
    return audioElement;
  };

  private removeAudioElement = () => {
    const rootElement = document.getElementById("root") as HTMLElement;
    const audioElement = document.getElementById(
      AudioPlayerClient.ElementId
    ) as HTMLAudioElement;
    if (rootElement && audioElement) rootElement.removeChild(audioElement);
  };

  public play = () => this.audioElement.play();

  public pause = () => this.audioElement.pause();

  public seek = (to: number) => (this.audioElement.currentTime = to);

  public selectTrack = (trackId: string) => {
    const track = this.currentLibrary?.find((x) => x.id === trackId);
    if (!track)
      throw new Error(`TrackId ${trackId} のトラックが library 内にありません`);
    if (!track.audioUrl)
      throw new Error(
        `trackId: ${track.id} のトラックには audioUrl がセットされていません`
      );
    this.audioElement.src = track.audioUrl;
    this.audioElement.onloadedmetadata = () => {
      this.currentTrack.setValue({
        ...track,
        duration: this.audioElement.duration,
      });
      this.audioElement.onloadedmetadata = null;
    };
    this.audioElement.onplay = () => this.isPlaying.setValue(true);
    this.audioElement.onplaying = () => this.isPlaying.setValue(true);
    this.audioElement.onpause = () => this.isPlaying.setValue(false);
    this.audioElement.onclose = () => this.isPlaying.setValue(false);
    this.audioElement.onabort = () => this.isPlaying.setValue(false);

    this.audioElement.onseeking = () => this.isSeeking.setValue(true);
    this.audioElement.onseeked = () => this.isSeeking.setValue(false);

    this.currentTrack.setValue(track);
  };

  public reset = () => {
    this.audioElement.pause();
    this.audioElement.src = "";
    this.currentLibrary = null;
    this.currentSeek.setValue(0);
    this.isPlaying.setValue(false);
    this.currentTrack.setValue(null);
  };

  public setLibrary = (library: IAudioPlayerTrack[]) =>
    (this.currentLibrary = library);
}
