import { useNavigationModule } from "@agp/shared.user-app/application/context/navigation";
import ServiceLocator from "../../../../domain/service-locator";
import { useCallback, useMemo } from "react";
import { LinedText } from "../../../components/lined-text";

export const MinpakuInfo = () => {
  const { push } = useNavigationModule();

  const localizedEntityRepository = ServiceLocator.LocalizedEntityRepository;
  const entities = localizedEntityRepository.entities.watch();
  const defaultArea = useMemo(
    () => entities.areas.find((x) => x.isDefault),
    [entities]
  );

  const handleClick = useCallback(() => {
    push(
      "tours",
      { areaId: defaultArea!.id },
      {
        pageInType: "fade",
        pageOutType: "fade",
      }
    );
  }, []);

  return (
    <div
      className="bg-[#210917] pt-5 h-[calc(var(--vh,1vh)*100)] text-white pb-8 flex flex-col gap-5"
      onClick={handleClick}
    >
      <img
        src="/minpaku-demo/logos/minpaku.svg"
        className="mx-auto h-28 flex-shrink-0 flex-grow-0"
      />
      <div className="flex-grow flex flex-col pt-6 pb-280 justify-center">
        <div
          className={
            "flex justify-center gap-10 max-w-[348px] pl-4 mr-auto ml-auto"
          }
        >
          <div className="text-center text-[#fbcd78] font-bold text-1xl">
            使いかた
          </div>
          <div className={"font-bold text-2lg"}>
            解説を聞きたい地域を選択すると、
            <br />
            一覧が表示されます。
          </div>
        </div>
        <div
          className={"flex flex-col mt-10 max-w-[348px] mr-auto ml-auto gap-8"}
        >
          <div className={"flex-col gap-4"}>
            <div className={"text-sm pl-4"}>メニューボタン</div>
            <MenuInfo
              imageUrl={"/minpaku-demo/icons/list-icon-active.svg"}
              title={"リスト"}
              description={
                "音声ガイドの一覧が表示されます。\nお聞きになりたいガイドを選択し、\n再生ボタンを押してください。"
              }
            />
          </div>
          <MenuInfo
            imageUrl={"/minpaku-demo/icons/map-active.svg"}
            title={"マップ"}
            description={"解説エリアのマップが\n表示されます。"}
          />
          <MenuInfo
            imageUrl={"/minpaku-demo/icons/keypad-active.svg"}
            title={"キーパッド"}
            description={
              "ガイド番号を入力すると、\n再生画面が表示されます。\n再生ボタンを押すと、\n解説を開くことができます。"
            }
          />
        </div>
        <div className={"flex flex-col mt-10 w-[348px] ml-auto mr-auto gap-4"}>
          <div>音声ガイド番号表示</div>
          <div className={"flex justify-between pl-1 items-center"}>
            <div
              className={
                "w-40 h-24 bg-white text-black text-7xl font-bold text-center content-center"
              }
            >
              1
            </div>
            <div
              className={
                "rounded-full w-24 h-24 bg-white text-black text-center content-center text-3xl font-bold "
              }
            >
              次へ
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

type MenuInfoProps = {
  imageUrl: string;
  title: string;
  description: string;
};

const MenuInfo = (props: MenuInfoProps) => {
  return (
    <div className={"flex gap-10 pl-5 pt items-center"}>
      <div className={"w-20 h-20 flex justify-center"}>
        <img src={props.imageUrl} alt="" className={"h-20"} />
      </div>
      <div className={"flex-col"}>
        <div>{props.title}</div>
        <div>
          <LinedText text={props.description} />
        </div>
      </div>
    </div>
  );
};
