import ServiceLocator from "../../../../../../domain/service-locator";
import styled from "styled-components";
import Icon from "../../../../../components/icon";
import { useCallback } from "react";
import { MinpakuSeekBar } from "../minpaku-seek-bar";

export const MinpakuPlayerMiddleController = ({
  height,
}: {
  height: number;
}) => {
  const guidePlayerModule = ServiceLocator.GuidePlayerModule;
  const playerLayoutModule = ServiceLocator.PlayerLayoutModule;

  const isPlaying = guidePlayerModule.isPlaying.watch();
  const canNext = guidePlayerModule.canNext.watch();
  const canPrev = guidePlayerModule.canPrev.watch();
  const currentSeek = guidePlayerModule.currentSeek.watch();
  const playerHeight = playerLayoutModule.playerHeight.watch();

  const handlePrev = useCallback(() => {
    if (currentSeek > 5) {
      guidePlayerModule.seek(0);
      return;
    }

    if (!canPrev) {
      guidePlayerModule.seek(0);
      return;
    }
    guidePlayerModule.prev();
  }, [canPrev, currentSeek, guidePlayerModule]);

  const handleNext = useCallback(() => {
    if (!canNext) return;
    guidePlayerModule.next();
  }, [canNext, guidePlayerModule]);

  const controllerHeight = `h-[${height}px]`;

  return (
    <div
      className={`fixed top-0 w-full pointer-events-none`}
      style={{ height: `${playerHeight}px` }}
    >
      <div
        className={`w-full ${controllerHeight} absolute bottom-0 bg-[#b8b2b8] flex flex-col py-4 px-10 pointer-events-auto`}
      >
        <MinpakuSeekBar withTime={true} />
        <div className={"flex justify-center items-center gap-16"}>
          <div className={"flex items-center gap-4"} onClick={handlePrev}>
            <img
              src={"/minpaku-demo/icons/left-arrow.svg"}
              width={20}
              alt={"leftArrow"}
            />
            <div className={"text-2xl font-bold"}>前へ</div>
          </div>
          <BigIcon
            type={isPlaying ? "pause" : "play"}
            onClick={(e: any) => {
              e.stopPropagation();
              guidePlayerModule.playOrPause();
            }}
            color={"black"}
          />
          <div className={"flex items-center gap-4"} onClick={handleNext}>
            <div className={"text-2xl font-bold"}>次へ</div>
            <img
              src={"/minpaku-demo/icons/right-arrow.svg"}
              width={20}
              alt={"rightArrow"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const BigIcon = styled(Icon)`
  width: 32px;
`;
