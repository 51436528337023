import { useCallback } from "react";
import { useNavigationModule } from "@agp/shared.user-app/application/context/navigation";

export const MinpakuNote = () => {
  const { push } = useNavigationModule();

  const handleClick = useCallback(() => {
    push(
      "info",
      {},
      {
        pageInType: "fade",
        pageOutType: "fade",
      }
    );
  }, []);

  return (
    <div
      className="bg-[#210917] pt-5 h-[calc(var(--vh,1vh)*100)] text-white pb-8 flex flex-col gap-5"
      onClick={handleClick}
    >
      <img
        src="/minpaku-demo/logos/minpaku.svg"
        className="mx-auto h-28 flex-shrink-0 flex-grow-0"
      />
      <div className="flex-grow flex justify-center flex-col pb-28">
        <div className="text-center text-[#fbcd78] font-bold text-2xl">
          お願い
        </div>
        <div className="flex flex-col gap-10 ml-10 mt-16">
          <NoteSentence
            text={"音声をお聞きになる際には、\nイヤホンをご利用ください"}
          />
          <NoteSentence
            text={
              "展示室内では、ほかのお客様や展示作品との\n間隔をあけ、ご利用ください"
            }
          />
        </div>
      </div>
    </div>
  );
};

const NoteSentence = (props: { text: string }) => {
  const split = props.text.split("\n");
  return (
    <div className="font-bold">
      <span className="text-[#fbcd78]">◉</span>
      {split[0]}
      {split.length > 1 &&
        split
          .filter((_, i) => i > 0)
          .map((x) => <div className="ml-5">{x}</div>)}
    </div>
  );
};
