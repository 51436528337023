import AnimatedPageTransition from "../../../domain/module/navigation/animated-page-transition";
import ServiceLocator from "../../../domain/service-locator";
import { MinpakuInfo } from "./minpaku";

const Info = () => {
  const uiTemplateModule = ServiceLocator.UiTemplateModule;

  return (
    <AnimatedPageTransition>
      <div className={"overflow-y-scroll hidden-scrollbar"}>
        {uiTemplateModule.uiTemplate === "Minpaku" ? <MinpakuInfo /> : <></>}
      </div>
    </AnimatedPageTransition>
  );
};

export default Info;
