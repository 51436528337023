import { PropsWithChildren } from "react";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";

type FadeModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

// TODO: Modal を tailwind css にリファクタ後、統合
export const FadeModal = (props: PropsWithChildren<FadeModalProps>) => {
  return (
    <Dialog
      open={props.isOpen}
      onClose={() => {
        props.onClose();
      }}
      className={`relative z-[600]`}
    >
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-black opacity-50 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 m-4 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-sm sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            {props.children}
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};
