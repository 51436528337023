import ServiceLocator from "../../../../domain/service-locator";
import { IAudioSpeechTrack } from "@agp/shared.user-app/module/guide-player.module/audio-client";
import { useMemo } from "react";
import { LinedText } from "../../../components/lined-text";
import styled from "styled-components";

export const PlayerDescription = () => {
  const guidePlayerModule = ServiceLocator.GuidePlayerModule;
  const playerLayoutModule = ServiceLocator.PlayerLayoutModule;
  const { theme } = ServiceLocator.ThemeStore;

  const playerMode = guidePlayerModule.playerMode.watch();
  const track = guidePlayerModule.currentTrack.watch() as IAudioSpeechTrack;
  const currentSeek = guidePlayerModule.currentSeek.watch();
  const playerRectInfo = playerLayoutModule.playerRectInfo.watch();

  const descriptionWrapperHeight = useMemo(
    () => playerRectInfo.fullPlayerHeight - playerRectInfo.middlePlayerHeight,
    [playerRectInfo]
  );

  return playerMode === "AudioSynthesis" ? (
    <DescriptionWrapper height={descriptionWrapperHeight}>
      {[...track.text].map((x, i) => (
        <span
          style={{
            color:
              i < currentSeek
                ? theme.color.primaryText
                : theme.color.secondaryText,
          }}
          key={`sentence-${i}`}
          onClick={() => guidePlayerModule.seek(i)}
        >
          {x === "\n" ? <br /> : x}
        </span>
      ))}
      <Margin height={30} />
    </DescriptionWrapper>
  ) : (
    <DescriptionWrapper height={descriptionWrapperHeight}>
      <LinedText text={track.text} />
      <Margin height={30} />
    </DescriptionWrapper>
  );
};

const DescriptionWrapper = styled.div<{ height: number }>`
  padding-top: 10px;
  line-height: 24px;
  font-size: 1.6rem;
  cursor: default;
  // background: ${(props) => props.theme.color.primaryBackground};
  height: ${(props) => props.height}px;
`;

const Margin = styled.div<{ height: number }>`
  height: ${(props) => props.height}px;
`;
