export type NavigationType = "push" | "back" | "change";

export interface NavigationOption {
  pageOutType?: PageTransitionType;
  pageInType?: PageTransitionType;
}

export type PageTransitionType = "none" | "fade" | "slideRight" | "slideLeft";

export type PageType =
  | "splash"
  | "info"
  | "note"
  | "top"
  | "areas"
  | "tours"
  | "guides"
  | "map"
  | "keypad"
  | "description";

export const PagePaths: { [key: string]: string } = {
  root: "/",
  splash: "/splash",
  info: "/info",
  note: "/note",
  description: "/description",
  top: "/top",
  keypad: "/keypad",
  areas: "/areas",
  tours: "/tours",
  guides: "/guides",
  map: "/map",
};
