import styled from "styled-components";
import { useEffect, useMemo, useRef, useState } from "react";
import { Image } from "semantic-ui-react";
import "@agp/shared.common/extensions/number.extensions";
import { LinedText } from "../../../../../components/lined-text";
import ServiceLocator from "../../../../../../domain/service-locator";
import { IAudioSpeechTrack } from "@agp/shared.user-app/module/guide-player.module/audio-client";
import { MinpakuPlayerMiddleController } from "./minpaku-player-middle-controller";

const controllerHeight = 90;

const MinpakuPlayerMiddleComponent = () => {
  const guidePlayerModule = ServiceLocator.GuidePlayerModule;
  const playerLayoutModule = ServiceLocator.PlayerLayoutModule;

  const scrollRef = useRef<HTMLDivElement>(null);

  const track = guidePlayerModule.currentTrack.watch();
  const playerRectInfo = playerLayoutModule.playerRectInfo.watch();
  const upperSegmentHeight = useMemo(
    () => playerRectInfo.middlePlayerHeight - controllerHeight,
    [playerRectInfo]
  );
  const playerType = playerLayoutModule.playerType.watch();

  const [isBigText, setIsBigText] = useState(false);

  useEffect(() => {
    scrollRef.current?.scrollTo({ top: 0 });
  }, [track?.trackNumber]);

  return (
    <>
      {track && (
        <>
          <Wrapper
            id="MiddlePlayer"
            isScrollable={playerType === "full"}
            ref={scrollRef}
            // onScroll={(e: any) => onScrollTopChanged(e.target.scrollTop)}
          >
            <UpperSegment
              height={upperSegmentHeight}
              onClick={() => playerLayoutModule.playerType.setValue("mini")}
            >
              <LargeThumbnail height={upperSegmentHeight * 0.8} imageUrl={""}>
                <Thumbnail
                  src={
                    track.thumbnailUrls && track.thumbnailUrls.length > 0
                      ? track.thumbnailUrls[0]
                      : "no-image.png"
                  }
                  $height={upperSegmentHeight * 0.8}
                />
              </LargeThumbnail>
              <GuideInfo>
                <Title>
                  {`${track.trackNumber.toPadString(2)}. ${track.name}`}
                </Title>
                <TextResizeButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsBigText(!isBigText);
                  }}
                >
                  <img src={"/text-resize.png"} alt={"textResize"} />
                </TextResizeButton>
              </GuideInfo>
            </UpperSegment>
            <Description isBigText={isBigText} />
            <div
              className={
                "absolute top-[10px] left-[50%] -translate-x-1/2 pointer-events-none"
              }
            >
              <div
                className={
                  "absolute bg-black rounded-full w-8 h-8 top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2"
                }
              />
              <img
                alt="expandable-button"
                src="/minpaku-demo/icons/expand-button.svg"
                className={`h-9 w-9 rotate-0`}
              />
            </div>
          </Wrapper>
          <MinpakuPlayerMiddleController height={controllerHeight} />
        </>
      )}
    </>
  );
};

export default MinpakuPlayerMiddleComponent;

const Description = ({ isBigText }: { isBigText: boolean }) => {
  const guidePlayerModule = ServiceLocator.GuidePlayerModule;
  const playerLayoutModule = ServiceLocator.PlayerLayoutModule;
  const { theme } = ServiceLocator.ThemeStore;

  const playerMode = guidePlayerModule.playerMode.watch();
  const track = guidePlayerModule.currentTrack.watch() as IAudioSpeechTrack;
  const currentSeek = guidePlayerModule.currentSeek.watch();
  const playerRectInfo = playerLayoutModule.playerRectInfo.watch();

  const descriptionWrapperHeight = useMemo(
    () => playerRectInfo.fullPlayerHeight - playerRectInfo.middlePlayerHeight,
    [playerRectInfo]
  );

  return playerMode === "AudioSynthesis" ? (
    <DescriptionWrapper height={descriptionWrapperHeight} isBigText={isBigText}>
      {[...track.text].map((x, i) => (
        <span
          style={{
            color:
              i < currentSeek
                ? theme.color.primaryText
                : theme.color.secondaryText,
          }}
          key={`sentence-${i}`}
          onClick={() => guidePlayerModule.seek(i)}
        >
          {x === "\n" ? <br /> : x}
        </span>
      ))}
      <Margin height={30} />
    </DescriptionWrapper>
  ) : (
    <DescriptionWrapper height={descriptionWrapperHeight} isBigText={isBigText}>
      <LinedText text={track.text} />
      <Margin height={20} />
    </DescriptionWrapper>
  );
};

const Wrapper = styled.div<{ isScrollable: boolean }>`
  padding-left: 25px;
  padding-right: 25px;
  will-change: transform;
  ${(props) =>
    props.isScrollable ? "overflow-y: scroll;" : "overflow-y: hidden;"}
  -ms-overflow-style: none;
  scrollbar-width: none;
  position: relative;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    display: none;
  }

  overscroll-behavior: none;
`;

const UpperSegment = styled.div<{ height: number }>`
  height: ${(props) => props.height}px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
`;

const Thumbnail = styled(Image)`
  /* フェードアウトのマスクを適用 */
  //mask-image: linear-gradient(to bottom, black 80%, transparent 100%);
  //-webkit-mask-repeat: no-repeat;
  //mask-repeat: no-repeat;
  //-webkit-mask-size: 100% 100%;
  //mask-size: 100% 100%;

  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const LargeThumbnail = styled.div<{ height: number; imageUrl: string }>`
  border-radius: 15px 15px 0 0;
  width: 117%;
  height: ${(props) => props.height}px;
  background: url(${(props) => props.imageUrl}) center;
  background-size: contain;
  //filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.3));
  overflow: hidden;
`;

const GuideInfo = styled.div`
  width: 100%;
  position: relative;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 2rem;
  line-height: 28px;
  color: ${(props) => props.theme.color.primaryText};
`;

// const TourNameWrapper = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   line-height: 28px;
//   gap: 10px;
// `;

const TextResizeButton = styled.div`
  cursor: pointer;
  aspect-ratio: 1;
  height: 60%;
  flex-shrink: 0;
`;

// const TourName = styled.div`
//   font-size: 1.6rem;
//   color: ${(props) => props.theme.color.primary};
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
// `;

const DescriptionWrapper = styled.div<{ height: number; isBigText: boolean }>`
  padding-top: 10px;
  line-height: ${(props) => (props.isBigText ? "30px" : "24px")};
  font-size: ${(props) => (props.isBigText ? "2.2rem" : "1.6rem")};
  cursor: default;
  // background: ${(props) => props.theme.color.primaryBackground};
  height: ${(props) => props.height}px;
`;

const Margin = styled.div<{ height: number }>`
  height: ${(props) => props.height}px;
`;
