import {
  IReadOnlyReactiveProperty,
  ReactivePropertyInstance,
} from "@agp/shared.common/use-reactive-property";
import { PlayerType } from "@agp/shared.user-app/module/guide-player.module/player-type";

interface PlayerRectInfo {
  miniPlayerHeight: number;
  middlePlayerHeight: number;
  fullPlayerHeight: number;
}

export class PlayerLayoutModule {
  private readonly _playerRectInfo =
    new ReactivePropertyInstance<PlayerRectInfo>(getPlayerHeight());
  public readonly playerRectInfo = this._playerRectInfo.toReactiveProperty();
  private readonly _playerType = new ReactivePropertyInstance<PlayerType>(
    "none"
  );
  public readonly playerType = this._playerType.toReactiveProperty();

  public get playerTranslateY(): IReadOnlyReactiveProperty<number> {
    return ReactivePropertyInstance.combineLatest(
      this._playerType,
      this._playerRectInfo,
      (playerType, playerHeight) =>
        playerType === "none"
          ? 0
          : playerType === "mini"
          ? -playerHeight.miniPlayerHeight
          : playerType === "middle"
          ? -playerHeight.middlePlayerHeight
          : -playerHeight.fullPlayerHeight
    );
  }

  public get playerHeight() {
    return ReactivePropertyInstance.combineLatest(
      this._playerType,
      this._playerRectInfo,
      (t, r) =>
        t === "mini"
          ? r.miniPlayerHeight
          : t === "middle"
          ? r.middlePlayerHeight
          : t === "full"
          ? r.fullPlayerHeight
          : 0
    );
  }

  public constructor() {
    let timer: NodeJS.Timeout;
    window.addEventListener("resize", () => {
      clearTimeout(timer);
      timer = setTimeout(
        () => this._playerRectInfo.setValue(getPlayerHeight()),
        300
      );
    });
  }
}

const getPlayerHeight = (): PlayerRectInfo => ({
  miniPlayerHeight: 90,
  middlePlayerHeight: window.innerHeight * 0.6,
  fullPlayerHeight: window.innerHeight * 0.96,
});
