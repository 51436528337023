import { DetailedTour, ExpandableTourCard } from "./expandable-tour-card";
import { useCallback, useMemo } from "react";
import ServiceLocator from "../../../../domain/service-locator";
import { MinpakuHeader } from "../../../components/layout/header/minpaku.header";
import { QuestionnaireCard } from "./questionnaire/questionnaire-card";

export const ToursAcoustiguide = () => {
  const localizedEntityRepository = ServiceLocator.LocalizedEntityRepository;
  const guidePlayerModule = ServiceLocator.GuidePlayerModule;
  const playerLayoutModule = ServiceLocator.PlayerLayoutModule;
  const entities = localizedEntityRepository.entities.watch();

  const currentAreaId =
    ServiceLocator.ApplicationStateRepository.currentAreaId.watch();
  const currentTrack = guidePlayerModule.currentTrack.watch();

  const currentTours = useMemo<DetailedTour[]>(
    () =>
      !currentAreaId
        ? []
        : entities.tours
            .filter((x) => x.areaId === currentAreaId)
            .map((x) => ({
              ...x,
              guides: entities.guides.filter((y) => y.tourId === x.id),
            })),
    [currentAreaId, entities]
  );

  const handleClickGuide = useCallback(
    (id: string) => {
      const shouldShowPlayer = guidePlayerModule.currentTrack.value === null;
      guidePlayerModule.select(id);
      if (shouldShowPlayer) playerLayoutModule.playerType.setValue("full");
    },
    [guidePlayerModule, playerLayoutModule.playerType]
  );

  return (
    <>
      <div className={"fixed top-0 left-0 w-full h-full bg-[#210917] -z-10"} />
      <div className="px-8 py-4 min-h-full">
        <MinpakuHeader />
        <div className={`flex flex-col gap-4 mb-28`}>
          {currentTours.map((x, i) => (
            <div key={`tour-card-${i}`}>
              <ExpandableTourCard
                tour={x}
                onClickGuide={handleClickGuide}
                labelColor={
                  i === 0
                    ? "yellow"
                    : i === 1
                    ? "purple"
                    : i === 2
                    ? "red"
                    : i === 3
                    ? "green"
                    : i === 4
                    ? "blue"
                    : "orange"
                }
                currentPlayingGuideId={currentTrack?.id}
              />
            </div>
          ))}
          <div>
            <QuestionnaireCard />
          </div>
        </div>
      </div>
    </>
  );
};
