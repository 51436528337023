import { ILocalStorageClient } from "../../infrastructure/local-storage-client";
import {
  IReadOnlyReactiveProperty,
  ReactivePropertyInstance,
} from "@agp/shared.common/use-reactive-property";
import { QuestionnaireData } from "../../entity/questionnaire-data";

const localStorageKey = "questionnaire_data";

export class QuestionnaireRepository {
  private readonly _localStorageClient: ILocalStorageClient;
  private readonly _savedQuestionnaireData: ReactivePropertyInstance<QuestionnaireData | null>;

  public savedQuestionnaireData: IReadOnlyReactiveProperty<QuestionnaireData | null>;

  public constructor(localStorageClient: ILocalStorageClient) {
    this._localStorageClient = localStorageClient;
    const savedData = this._localStorageClient.get(localStorageKey) || null;
    this._savedQuestionnaireData =
      new ReactivePropertyInstance<QuestionnaireData | null>(savedData);
    this.savedQuestionnaireData =
      this._savedQuestionnaireData.toReadOnlyReactiveProperty();
  }

  public saveQuestionnaireData(questionnaireData: QuestionnaireData) {
    this._savedQuestionnaireData.setValue(questionnaireData);
    this._localStorageClient.set(localStorageKey, questionnaireData);
  }
}
